<template>
	<v-container>
		<!-- Admin Button Add -->
		<v-row v-if="user && user.role === 'admin'">
			<v-col>
				<v-btn color="blue" dark to="/admin/article/create"
					><v-icon small> mdi-plus </v-icon>{{ $t('add') }}
				</v-btn>
			</v-col>
		</v-row>

		<!-- User View -->
		<v-row v-if="user && user.role !== 'admin'">
			<v-col
				sm="6"
				md="4"
				xl="3"
				v-for="article in publishedArticles"
				:key="article._id"
			>
				<v-card class="mx-auto" min-width="185px">
					<v-img
						v-if="article.image"
						class="blue--text align-end"
						height="300px"
						:src="`${article.image}`"
					>
						<v-card-subtitle>{{ article.categories }}</v-card-subtitle>
					</v-img>

					<v-card-title> {{ article.title }} </v-card-title>
					<v-card-text class="text--primary">
						<div v-html="article.description" class="text-preview"></div>
					</v-card-text>

					<v-card-actions>
						<v-btn
							v-if="user && user.role === 'admin'"
							color="blue"
							dark
							:to="{
								name: 'ArticleCreateEdit',
								params: { slug: article.slug },
							}"
						>
							{{ $t('edit') }}
						</v-btn>

						<v-spacer></v-spacer>

						<v-btn color="primary" text @click="readMore(article)">
							{{ $t('readMore') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<!-- Admin View -->
		<v-row v-else>
			<v-col
				sm="6"
				md="4"
				xl="3"
				v-for="article in articles"
				:key="article._id"
			>
				<v-card class="mx-auto" min-width="185px">
					<v-img
						v-if="article.image"
						class="blue--text align-end"
						height="300px"
						:src="`${article.image}`"
					>
						<span v-for="(tag, i) in article.categories" :key="i" class="tags">
							#{{ tag }}
						</span>
					</v-img>

					<v-card-title> {{ article.title }} </v-card-title>
					<v-card-text class="text--primary">
						<div v-html="article.description" class="text-preview"></div>
					</v-card-text>

					<v-card-actions>
						<v-btn
							v-if="user && user.role === 'admin'"
							color="blue"
							dark
							:to="{
								name: 'ArticleCreateEdit',
								params: { slug: article.slug },
							}"
						>
							{{ $t('edit') }}
						</v-btn>

						<v-spacer></v-spacer>

						<v-btn color="primary" text @click="readMore(article)">
							{{ $t('readMore') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>

		<!-- Loader -->
		<div v-if="isLoading" class="text-center">
			<v-progress-circular indeterminate color="primary"></v-progress-circular>
		</div>
	</v-container>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'Blog',

	data() {
		return {
			url: process.env.VUE_APP_BASE_URL || 'http://localhost:5000/api/v1',
			isLoading: false,
			articles: [],
		};
	},
	computed: {
		...mapState('auth', ['user']),
		publishedArticles() {
			return (this.articles = this.articles.filter(
				article => article.published === true,
			));
		},
	},
	methods: {
		readMore(article) {
			this.$router.push({ name: 'Article', params: { slug: article.slug } });
		},
		edit(article) {},
		async getArticles() {
			this.isLoading = true;

			try {
				const res = await this.$http.get(`${this.url}/articles`);

				this.articles = res.data.data;
			} catch (error) {
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		},
	},
	mounted() {
		this.getArticles();
	},
};
</script>

<style lang="scss" scoped>
img {
	height: 200px;
	width: 200px;
}

.v-card__text {
	padding-bottom: 0 !important;
}

.tags {
	color: steelblue;
	font-size: 12px;
	margin: 0 2px 0 0;
	padding: 0 3px 0 2px;
	background: #fff;
	border-radius: 8px;
}

// .text-preview {
// 	white-space: nowrap;
// 	word-break: normal;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// }
</style>
